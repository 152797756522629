/* eslint-disable multiline-ternary */
import { action, makeAutoObservable } from 'mobx';
import app from '../../app';
import { v4 as uuidv4 } from 'uuid';

export interface IModalStore {
  modalToShow: string;
}

export class ModalStore implements IModalStore {
  id = uuidv4();
  isOpen = false;
  modalToShow = '';
  constructor() {
    makeAutoObservable(this);
  }

  @action closeModal(): void {
    this.isOpen = false;
    this.modalToShow = '';
  }
  @action openMetaMaskNetworkModal(): void {
    this.isOpen = true;
    this.modalToShow = 'metamaskNetwork';
  }
  @action openModal(modalToOpen: string): void {
    this.isOpen = true;
    this.modalToShow = modalToOpen;
  }
  @action handleClaimClicked(): void {
    this.isOpen = true;
    this.modalToShow = 'claim';
  }

  @action handleDelegateClicked(): void {
   // if (app.wallet.wSGBBalance > 0) {
      this.isOpen = true;
      this.modalToShow = 'delegate';
   // }
  }
  @action handleWrapClicked(): void {
   // if (app.wallet.sgbBalance > 0) {
      this.isOpen = true;
      this.modalToShow = 'wrap';
   // }
  }
  @action handleUnWrapClicked(): void {
   // if (app.wallet.wSGBBalance > 0) {
      this.isOpen = true;
      this.modalToShow = 'unwrap';
  //  }
  }



}
