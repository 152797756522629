import { makeAutoObservable } from "mobx";
import { FlareStore } from "../@state/flare/flareStore";
import { ModalStore } from "../@state/modal/modalStore";
import { SongbirdStore } from "../@state/songbird/songbirdStore";
import { UserStore } from "../@state/user/userStore";
import { Web3Store } from "../@state/web3/web3Store";

class AppState {
  flare: FlareStore;
  songbird: SongbirdStore;
  modal: ModalStore;
  user: UserStore;
  web3: Web3Store;

  constructor() {
    makeAutoObservable(this);
    this.flare = new FlareStore();
    this.songbird = new SongbirdStore();
    this.modal = new ModalStore();
    this.user = new UserStore();
    this.web3 = new Web3Store();
  }
}

const app = new AppState();
export default app;
