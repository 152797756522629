import { ethers } from 'ethers';
import React from 'react';
import { Modal, Button, FormGroup, Col, Input, Row } from 'reactstrap';
import app from '../../../app';
import { nftpAddressSGB } from "../../../const/addresses";

export const NFTpClaimModalSGB = (): JSX.Element => {
  const tsoAddress = "0x08Eb910E9288E5C6baF6e1a74D5D1D23284360a4";

  const handleClaimClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      try {
        const abi = ['function claimRewardsSecondaryTSO(address _tsoAddress) external'];
        const tokenContract = new ethers.Contract(nftpAddressSGB, abi, prov.getSigner());
        await tokenContract.claimRewardsSecondaryTSO(tsoAddress);
        app.modal.closeModal();
      } catch (error) {
        console.log('error ', error);
      }
      // const vpReader = new FTSOVotePower(app.wallet.wSGBAddress, app.wallet.rewardsManagerAddress, prov);
      //const results = await vpReader.claimRewards(app.user.address, [1, 2, 3]);
      //console.log('results ', results);
      // console.log('undelegateAll result hash ', result.hash)
      app.modal.closeModal();
    } catch (error) {
      console.log('error ', error);
    }
  };

  const amountIsValid = (): boolean => {
    return app.songbird.nftpPendingRewards > 0;
  };

  const truncateTo5Digits = (valueToTruncate: number): string => {
    return valueToTruncate.toFixed(5);
  };

  return (
    <Modal className="Modal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">Claim</h4>
        <div className="available-amount">Available : <strong>{truncateTo5Digits(app.songbird.nftpPendingRewards)}</strong></div>
      </div>
      <div className="modal-body">
        <p>
          You are about to claim <strong>{app.songbird.nftpPendingRewards} NFTps.</strong>
        </p>
        <div className="inputlines">
          <FormGroup>
            <label>Amount</label>
            <Row>
              <Col sm="12">
                <Input min="0" placeholder="Amount" id="amount" type="number" value={app.songbird.nftpPendingRewards} />
              </Col>
            </Row>
          </FormGroup>
        </div>
        <div className="modaldetails">
          <span className="left">You will claim</span>
          <span className="right">{truncateTo5Digits(app.songbird.nftpPendingRewards)} NFTP</span>
        </div>
        {/* <div className="modaldetails">
          <span className="left">Network fees</span>
          <span className="right">0.0012</span>
        </div> */}

      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Close
        </Button>

        <div>
          {amountIsValid() ? <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleClaimClicked(e)}>
            Claim
          </Button> : <></>}

        </div>
      </div>
    </Modal>
  );
};
