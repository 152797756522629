import { ethers } from "ethers";
import { wFLRAddress, wSGBAddress } from "../const/addresses";



export const getVotePowerPercentageSongbird = async (delegatorAddress: string, tsoAddress: string): Promise<number> => {
  let valueToReturn = 0;
  try {
    const abi = ['function delegatesOf(address _owner) external view returns(address[], uint256[], uint256, uint256)'];
    const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const wsgbContract = new ethers.Contract(wSGBAddress, abi, prov);
    const result = await wsgbContract.delegatesOf(delegatorAddress);
    const listOfDelegates = result[0];
    const tsoIndex = findTSOIndexInArray(tsoAddress, listOfDelegates);
    if (tsoIndex === -1) {
      valueToReturn = 0;
    } else {
      const listOfDelegatedBps = result[1];
      const basisPoints = listOfDelegatedBps[0].toNumber();
      valueToReturn = basisPoints / 100;
      //valueToReturn
    }
    return valueToReturn;
  } catch (error) {
    console.log('error ', error);
    return 0;
  }
};

export const getVotePowerPercentageFlare = async (delegatorAddress: string, tsoAddress: string): Promise<number> => {
  let valueToReturn = 0;
  try {
    const abi = ['function delegatesOf(address _owner) external view returns(address[], uint256[], uint256, uint256)'];
    const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const wsgbContract = new ethers.Contract(wFLRAddress, abi, prov);
    const result = await wsgbContract.delegatesOf(delegatorAddress);
    const listOfDelegates = result[0];
    const tsoIndex = findTSOIndexInArray(tsoAddress, listOfDelegates);
    if (tsoIndex === -1) {
      valueToReturn = 0;
    } else {
      const listOfDelegatedBps = result[1];
      const basisPoints = listOfDelegatedBps[0].toNumber();
      valueToReturn = basisPoints / 100;
      //valueToReturn
    }
    return valueToReturn;
  } catch (error) {
    console.log('error ', error);
    return 0;
  }
};

const findTSOIndexInArray = (addressToFind: string, arrayToCheck: string[]): number => {
  let valueToReturn = -1;
  for (let i = 0; i < arrayToCheck.length; i++) {
    if (arrayToCheck[i].toLowerCase() === addressToFind.toLowerCase()) {
      valueToReturn = i;
      break;
    }
  }
  return valueToReturn;
};