import { ethers } from "ethers";
import { ftsoManagerAddressFlare } from "../const/addresses";



export const getFlareVotePowerBlock = async (epochNumber: number): Promise<number> => {
  const abi = ['function rewardEpochs(uint256 _rewardEpochId) view returns (uint256,uint256,uint256 )'];
  const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
  const ftsoManager = new ethers.Contract(ftsoManagerAddressFlare, abi, prov);
  const epochData = await ftsoManager.rewardEpochs(epochNumber);
  return epochData[0].toNumber();
};