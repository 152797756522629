import { BigNumber, ethers } from "ethers";
import app from "../app";
import { sgbRewardsManagerAddress, flareRewardsManagerAddress } from "../const/addresses";
const abi = ["function getStateOfRewards(address _beneficiary, uint256 _rewardEpoch) external view returns(address[] memory _dataProviders,uint256[] memory _rewardAmounts, bool[] memory _claimed,bool _claimable)"];

export const getPendingRewardsSgb = async (epochNumber: number): Promise<number> => {
  if (window.ethereum) {
    const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const rewardsContract = new ethers.Contract(sgbRewardsManagerAddress, abi, prov);
    const results = await rewardsContract.getStateOfRewards(app.user.address, epochNumber);
    const alreadyClaimed = results[2][0];
    const listOfRewardAmounts2 = results[1];
    let value1 = BigNumber.from(0);
    if (listOfRewardAmounts2.length > 0) {
      for (let i = 0; i < listOfRewardAmounts2.length; i++) {
        if (!alreadyClaimed) {
          const rewardAmount: BigNumber = listOfRewardAmounts2[i];
          value1 = value1.add(rewardAmount);
          const formattedResult = parseFloat(app.web3.convertWEIToETH(value1.toString()));
          const trimmedResult = formattedResult.toFixed(4);
          return parseFloat(trimmedResult);
        }
      }
    }
    return 0;
  }
  return 0;
};

export const getPendingRewardsFlr = async (epochNumber: number): Promise<number> => {
  if (window.ethereum) {
    const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const rewardsContract = new ethers.Contract(flareRewardsManagerAddress, abi, prov);
    const results = await rewardsContract.getStateOfRewards(app.user.address, epochNumber);
    const alreadyClaimed = results[2][0];
    const listOfRewardAmounts2 = results[1];
    let value1 = BigNumber.from(0);
    if (listOfRewardAmounts2.length > 0) {
      for (let i = 0; i < listOfRewardAmounts2.length; i++) {
        if (!alreadyClaimed) {
          const rewardAmount: BigNumber = listOfRewardAmounts2[i];
          value1 = value1.add(rewardAmount);
          const formattedResult = parseFloat(app.web3.convertWEIToETH(value1.toString()));
          const trimmedResult = formattedResult.toFixed(4);
          return parseFloat(trimmedResult);
        }
      }
    }
    return 0;
  }
  return 0;
};