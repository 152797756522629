// ** Router Import
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { observer } from 'mobx-react';
import Home from "../components/Home";
import FAQs from "../components/FAQs";
import DelegatePage from "../delegate/DelegatePage";
import app from ".";
import ModalShared from "../components/modal-shared/ModalShared";

const App = observer((): JSX.Element => {
  const [web3Initialized, setWeb3Initialized] = useState(false);
  //Initialize Web3 Window Listeners
  const setWindowListeners = async () => {
    if (window.ethereum !== undefined) {
      if (!web3Initialized) {
        window.ethereum.on('chainChanged', app.web3.setChainId);
        window.ethereum.on('accountsChanged', app.web3.setAccounts);
        await app.web3.initializeWeb3();
        setWeb3Initialized(true);
      }
      if (app.web3.isSongbirdNetwork()) {
        app.songbird.loadUserDataSongbird();
        app.modal.isOpen = false;
        app.modal.modalToShow = '';
      } else if (app.web3.isFlareNetwork()) {
        app.flare.loadUserDataFlare();
        app.modal.isOpen = false;
        app.modal.modalToShow = '';
      } else {
        app.modal.openModal("metamaskNetwork");
      }
    }
  }; 

  React.useEffect(() => {
    //Initialize Web3 Window Listeners
    //  window.addEventListener('DOMContentLoaded', setWindowListeners);
    //app.fb.initializeFB();
    setWindowListeners();
  }, [app.user.address, app.web3.chainId]);

  return (
    <Router>
      <ModalShared />
      <Switch>
        <Route
          path="/"
          exact={true}
          component={Home}
        />
        <Route
          path="/delegate"
          exact={true}
          component={DelegatePage}
        />
        <Route
          path="/faq"
          exact={true}
          component={FAQs}
        />
      </Switch>
    </Router>
  );
});

export default App;
