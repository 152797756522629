import React from 'react';
import { Modal, Button } from 'reactstrap';
import app from '../../../app';


const MetaMaskModal = (): JSX.Element => {

  const handleAddSongbirdClicked = () => {
    window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x13',
          rpcUrls: ['https://songbird-api.flare.network/ext/C/rpc'],
          chainName: 'Songbird',
          nativeCurrency: { name: 'Songbird', decimals: 18, symbol: 'SGB' },
          blockExplorerUrls: ['https://songbird-explorer.flare.network/'],
        },
      ],
    });
  };

  const handleAddFlareClicked = () => {
    window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0xe',
          rpcUrls: ['https://flare-api.flare.network/ext/C/rpc'],
          chainName: 'Flare',
          nativeCurrency: { name: 'Flare', decimals: 18, symbol: 'FLR' },
          blockExplorerUrls: ['https://flare-explorer.flare.network/'],
        },
      ],
    });
  };



  return (
    <Modal className="Modal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()} data-backdrop="static" data-keyboard="false" >
      <div className="modal-header">
        <h4 className="title title-up text-center"><span>Action required: </span>Switch network</h4>
      </div>
      <div className="modal-body">
        <p>
          To be able to Delegate your FLR or SGB, you need to connect your wallet to either the Flare or Songbird Network.
        </p>

      </div>
      <div className="modal-footer justify-content-center">
        <Button color="danger" type="button" onClick={() => handleAddSongbirdClicked()}>
          Add Songbird Network To Metamask
        </Button>
      </div>
      <div className="modal-footer justify-content-center">
        <Button color="danger" type="button" onClick={() => handleAddFlareClicked()}>
          Add Flare Network To Metamask
        </Button>
      </div>
    </Modal >
  );
};
export default MetaMaskModal;
