import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import visibility from "../assets/img/visibility.svg";
import digitalWallet from "../assets/img/digital-wallet.svg";

import "./Delegate.css";
import app from "../app";
import { observer } from "mobx-react";
import DelegateSongbirdPage from "./delegate-songbird/DelegateSongbirdPage";
import DelegateFlarePage from "./delegate-flare/DelegateFlarePage";

const Delegate = observer((): JSX.Element => {
  const [chainName, setChainName] = React.useState("");

  const getSection = (): JSX.Element => {
    if (chainName === "Songbird") {
      return <DelegateSongbirdPage></DelegateSongbirdPage>;
    } else if (chainName === "Flare") {
      return <DelegateFlarePage></DelegateFlarePage>;
    } else {
      return <></>;
    }

  };

  const loadChainName = () => {
    if (app.web3.isSongbirdNetwork()) {
      setChainName("Songbird");
    } else if (app.web3.isFlareNetwork()) {
      setChainName("Flare");
    } else {
      setChainName("");
    }
  };

  React.useEffect(() => {
    loadChainName();
  }, [app.web3.chainId]);

  return (
    // <div id="wrapper">
    //   {/* Header */}
    //   <Header />
    //   {/* End Header */}
    //   <section
    //     className="vc_row wpb_row vc_row-fluid banner-salvia section-bg-salvia vc_row-o-equal-height vc_row-o-content-middle vc_row-flex banner banner-full d-flex align-items-center">

    //     {/* <div className="container pt-100">
    //       <div className="row">
    //         <div className="col-lg-8 col-sm-8 pb-40">
    //           <div className="card shadow assets-card">
    //             <div className="p-4">
    //               <div className="card-header-content">
    //                 <div className="p-10">
    //                   <img src={digitalWallet} alt="wallet" width="30px" style={{ paddingRight: "4px", paddingTop: "8px" }} />
    //                   <div
    //                     className="h-amount d-inline-block mr-2"> {cardHeader.amount || ""} </div>
    //                 </div>
    //                 <div className="h-delegated">
    //                   <div className="h-delegated-text">{cardHeader.delegated || ""}</div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="card-body">
    //               <div className="table-header">
    //                 <div className="row">
    //                   <div className="col-md-5">
    //                     Asset
    //                   </div>
    //                   <div className="col-md-3">
    //                     <button className="btn-hide-balance">
    //                       <img src={visibility} alt="kal" width="16px" style={{ verticalAlign: "middle" }} />
    //                     </button>
    //                     Balance
    //                   </div>
    //                   <div className="col-md-4">
    //                     Action
    //                   </div>
    //                 </div>
    //               </div>
    //               <div>
    //                 {
    //                   cardDetail && cardDetail.map((item, index) => {
    //                     return (
    //                       <div className="table-body-content" key={index}>
    //                         <div className="row p-2">
    //                           <div className="col-md-5">
    //                             {item.assetName || ""}
    //                           </div>
    //                           <div className="col-sm-3">
    //                             {item.balance || ""}
    //                           </div>
    //                           <div className="col-md-4">
    //                             {item && item.action && item.action.map((btn, index) => {
    //                               return (
    //                                 <div key={index} className="d-inline-block">
    //                                   <button className="custom-button"
    //                                     type="button">
    //                                     <img src={btn.icon} alt={btn.name} style={{ marginRight: "3px", height: "20px", verticalAlign: "middle" }} />
    //                                     {btn.name}
    //                                   </button>
    //                                 </div>
    //                               );
    //                             })}
    //                           </div>
    //                         </div>
    //                       </div>
    //                     );
    //                   })
    //                 }
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-lg-4 col-sm-4 pb-40">
    //           <div className="text-center">
    //             {
    //               rewardCard && rewardCard.map((item, index) => {
    //                 return (
    //                   <div className="pb-2" key={index}>
    //                     <div className="card shadow assets-card">
    //                       <div className="card-body">
    //                         <div>
    //                           <span className="delegated-text">{item.rewardButton || ""}</span>
    //                         </div>
    //                         <div>
    //                           <span className="delegated-text">{item.delegated || ""}</span>
    //                           <button className="reward-button ml-1">{item.amountButton || ""}</button>
    //                         </div>
    //                         <div className="amount-description">{item.amountDescription || ""}</div>
    //                         <div className="delegated-text">
    //                           {item.amountStatus || ""}
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 );
    //               })
    //             }
    //           </div>
    //         </div>
    //       </div>
    //     </div> */}
    //   </section>
    //   <Footer />
    //   <a href="#" id="back-to-top" />
    // </div>
    <div>
      {getSection()}
  </div>
    
  );
});

export default Delegate;
