import React, {useEffect, useState} from "react";
import Header from "./Header";
import Footer from "./Footer";
import FAQs from "./FAQs";

const Home = (): JSX.Element => {
    // const [isLoading, setLoading] = useState<boolean>(true);
    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 5000);
    // }, []);
    return (
        <div>
            <div id="wrapper">
                {/* Header */}
                <Header/>
                {/* End Header */}
                <section
                    className="vc_row wpb_row vc_row-fluid banner-salvia section-bg-salvia vc_row-o-equal-height vc_row-o-content-middle vc_row-flex banner banner-full d-flex align-items-center">
                    <div id="particles-js" className="particles-container particles-js"/>
                    <div className="container">
                        <div className="banner-content">
                            <div className="row">
                                <div
                                    className="res-m-bttm-lg text-center text-lg-left wpb_column vc_column_container vc_col-sm-12 vc_col-md-6">
                                    <div className="vc_column-inner ">
                                        <div className="wpb_wrapper">
                                            <div className="wpb_text_column wpb_content_element ">
                                                <div className="wpb_wrapper">
                                                    <h1 className="animated" data-animate="fadeInUp"
                                                        data-delay="0.65">Passive Income</h1>
                                                    <h3 className="animated" data-animate="fadeInUp"
                                                        data-delay="0.65">Delegate your votes & start earning!</h3>
                                                    <p className="lead animated" data-animate="fadeInUp"
                                                       data-delay="0.75">
                                                        Utilize the Flare Times Series Oracle to earn passive income
                                                        by delegating your voting power while maintaining full control of your tokens! Join the world at WorldsFTSO and delegate now!
                                                    </p>

                                                    <ul className="btns animated" data-animate="fadeInUp"
                                                        data-delay="0.85">
                                                        <li><a className="btn btn-outline" href="/delegate">Delegate</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"/>
                </section>
                {/*<section className="vc_row wpb_row vc_row-fluid section-pad section-bg-alt">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div*/}
                {/*                className="wpb_column vc_column_container vc_col-sm-8 vc_col-md-offset-3 vc_col-md-6 vc_col-sm-offset-2">*/}
                {/*                <div className="vc_column-inner ">*/}
                {/*                    <div className="wpb_wrapper">*/}
                {/*                        <div className="wpb_text_column wpb_content_element  text-center">*/}
                {/*                            <div className="wpb_wrapper">*/}
                {/*                                <div className="section-head-s8">*/}
                {/*                                    <h2 className="section-title-s8 animated" data-animate="fadeInUp"*/}
                {/*                                        data-delay=".1">Solutions</h2>*/}
                {/*                                    <p className="lead animated" data-animate="fadeInUp"*/}
                {/*                                       data-delay=".2">Our method and vision</p>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div data-animate="fadeInUp" data-delay="0.3"*/}
                {/*                 className="res-m-bttm-lg wpb_column vc_column_container vc_col-sm-6 vc_col-md-4 animated">*/}
                {/*                <div className="vc_column-inner ">*/}
                {/*                    <div className="wpb_wrapper">*/}
                {/*                        <div*/}
                {/*                            className="wpb_single_image wpb_content_element vc_align_center  vc_custom_1536822788550">*/}
                {/*                            <figure className="wpb_wrapper vc_figure">*/}
                {/*                                <div className="vc_single_image-wrapper   vc_box_border_grey"><img*/}
                {/*                                    className="vc_single_image-img "*/}
                {/*                                    src="wp-content/uploads/sites/29/2018/09/solution-icon-a-90x90.png"*/}
                {/*                                    width={90} height={90} alt="solution-icon-a"*/}
                {/*                                    title="solution-icon-a"/></div>*/}
                {/*                            </figure>*/}
                {/*                        </div>*/}
                {/*                        <div className="wpb_text_column wpb_content_element  text-center">*/}
                {/*                            <div className="wpb_wrapper">*/}
                {/*                                <h5>Global Single-Platform:</h5>*/}
                {/*                                <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis*/}
                {/*                                    suscipit laboriosam, nisi ut aliquid eos qui ratione voluptatem*/}
                {/*                                    sequi nesciunt.</p>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div data-animate="fadeInUp" data-delay="0.4"*/}
                {/*                 className="res-m-bttm-lg wpb_column vc_column_container vc_col-sm-6 vc_col-md-4 animated">*/}
                {/*                <div className="vc_column-inner ">*/}
                {/*                    <div className="wpb_wrapper">*/}
                {/*                        <div*/}
                {/*                            className="wpb_single_image wpb_content_element vc_align_center  vc_custom_1536822797818">*/}
                {/*                            <figure className="wpb_wrapper vc_figure">*/}
                {/*                                <div className="vc_single_image-wrapper   vc_box_border_grey"><img*/}
                {/*                                    className="vc_single_image-img "*/}
                {/*                                    src="wp-content/uploads/sites/29/2018/09/solution-icon-b-90x90.png"*/}
                {/*                                    width={90} height={90} alt="solution-icon-b"*/}
                {/*                                    title="solution-icon-b"/></div>*/}
                {/*                            </figure>*/}
                {/*                        </div>*/}
                {/*                        <div className="wpb_text_column wpb_content_element  text-center">*/}
                {/*                            <div className="wpb_wrapper">*/}
                {/*                                <h5>Be Safe and Secure:</h5>*/}
                {/*                                <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut*/}
                {/*                                    fugit, sed quia consequuntur magni dolores eos qui ratione*/}
                {/*                                    voluptatem sequi nesciunt.</p>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div data-animate="fadeInUp" data-delay="0.5"*/}
                {/*                 className="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-offset-0 vc_col-md-offset-0 vc_col-md-4 vc_col-sm-offset-3 animated">*/}
                {/*                <div className="vc_column-inner ">*/}
                {/*                    <div className="wpb_wrapper">*/}
                {/*                        <div*/}
                {/*                            className="wpb_single_image wpb_content_element vc_align_center  vc_custom_1536822805191">*/}
                {/*                            <figure className="wpb_wrapper vc_figure">*/}
                {/*                                <div className="vc_single_image-wrapper   vc_box_border_grey"><img*/}
                {/*                                    className="vc_single_image-img "*/}
                {/*                                    src="wp-content/uploads/sites/29/2018/09/solution-icon-c-90x90.png"*/}
                {/*                                    width={90} height={90} alt="solution-icon-c"*/}
                {/*                                    title="solution-icon-c"/></div>*/}
                {/*                            </figure>*/}
                {/*                        </div>*/}
                {/*                        <div className="wpb_text_column wpb_content_element  text-center">*/}
                {/*                            <div className="wpb_wrapper">*/}
                {/*                                <h5>Decentralize Payment Systems</h5>*/}
                {/*                                <p>Empor incididunt ut labore et dolore magna aliqua. Ut enim ad minim*/}
                {/*                                    veniam, quis nostrud exercitation ullamco laboris fugiat nulla sed*/}
                {/*                                    do eiusmod.</p>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="clearfix"/>*/}
                {/*</section>*/}
                {/*<Prices/>*/}
                <FAQs/>
                <Footer/>
                <a href="#" id="back-to-top"/>
                {/* Preloader !remove please if you do not want */}
                {/*<Loader isLoading={isLoading}/>*/}
                {/* Preloader End */}
            </div>
        </div>
    );
};

export default Home;
