import { BigNumber } from "@ethersproject/bignumber";
import { ethers } from "ethers";
import { wSGBAddress, wFLRAddress } from "../const/addresses";

export const getDelegatedAmountAtVPBFlare = async (vpb: number, tsoAddress: string, delegatorAddress: string): Promise<number> => {
  let valueToReturn: BigNumber = BigNumber.from(0);
  try {
    const abi = ['function votePowerFromToAt(address _from, address _to, uint _blockNumber) external view returns(uint256)'];
    const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const ftsoManager = new ethers.Contract(wFLRAddress, abi, prov);
    valueToReturn = await ftsoManager.votePowerFromToAt(delegatorAddress, tsoAddress, vpb);
    const formattedAmount = ethers.utils.formatEther(valueToReturn).toString();
    return Number.parseInt(formattedAmount);
  } catch (error) {
    console.log('error ', error);
    return valueToReturn.toNumber();
  }
};

export const getDelegatedAmountAtVPBSongbird = async (vpb: number, tsoAddress: string, delegatorAddress: string): Promise<number> => {
  let valueToReturn: BigNumber = BigNumber.from(0);
  try {
    const abi = ['function votePowerFromToAt(address _from, address _to, uint _blockNumber) external view returns(uint256)'];
    const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const ftsoManager = new ethers.Contract(wSGBAddress, abi, prov);
    valueToReturn = await ftsoManager.votePowerFromToAt(delegatorAddress, tsoAddress, vpb);
    const formattedAmount = ethers.utils.formatEther(valueToReturn).toString();
    return Number.parseInt(formattedAmount);
  } catch (error) {
    console.log('error ', error);
    return valueToReturn.toNumber();
  }
};