import { action, makeAutoObservable } from "mobx";

export interface IUserStore {
  userName: string;
  address: string;
  isConnected: boolean;
}

export class UserStore implements IUserStore {
  userName = "";
  address = "";
  isConnected = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action setUserName = (userName: string): void => {
    this.userName = userName;
  };
  @action setIsConnected = (isConnected: boolean): void => {
    this.isConnected = isConnected;
  };
  @action setUserAddress = (address: string): void => {
    this.address = address;
  };
  
}
