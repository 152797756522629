import React from "react";
import discord from '../assets/img/discord.png';

const Footer = (): JSX.Element => (
    <div
        className="section footer-section footer-particle section-pad-sm section-bg-dark footer-salvia section-bg-salvia overlay-shape">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 res-m-bttm footer-description">
                    <div id="custom_html-5" className="widget_text widget footer-widget widget_custom_html">
                        <div className="textwidget flex-row custom-html-widget">
                            <ul className="widget-list">
                                <li>WorldsFTSO.com, is dedicated to providing the most value to our users and help them earn passive income!
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* end col-lg-3 */}

                <div className="col-lg-2 res-m-bttm footer-description">
                    <div id="custom_html-2" className="widget_text widget footer-widget widget_custom_html">
                        <a href="/delegate" rel="noopener noreferrer" className="btn btn-circle btn-sm brand-twitter">
                            Delegate
                        </a>
                    </div>
                </div>
                {/* end col-lg-3 */}
                <div className="col-lg-2 res-m-bttm">
                    <div id="custom_html-3" className="widget_text widget footer-widget widget_custom_html">
                        <h6>Follow us</h6>
                            <a href="https://twitter.com/WorldsFTSO" rel="noreferrer" target="_blank">
                                <i className="fa fa-twitter fa-lg twitter-icon" aria-hidden="true" />
                            </a>
                            <a href="https://discord.gg/zRfHWxdM" rel="noreferrer" target="_blank">
                                <img src={discord} width={30} height={20} className="discord-icon"/>
                            </a>
                    </div>
                </div>
            </div>
            <div className="gaps size-1x d-none d-lg-block dropdown-divider mt-4"/>
            <div>
                <ul className="footer-list text-left">
                    <li>© 2021<a href="http://WorldsFTSO.com" rel="noreferrer" target="_blank" style={{color: "#eceacc"}}>WorldsFTSO.com.</a> All Rights Reserved
                    </li>
                </ul>
            </div>
        </div>
    </div>
);

export default Footer;
