import React from "react";

const FAQs = (): JSX.Element => (
    <section id="faqs" className="vc_row wpb_row vc_row-fluid section-pad section-bg-alt">
        <div className="container">
            <div className="row">
                <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-md-4">
                    <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                            <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                    <div className="section-head-s8">
                                        <h2 className="section-title-s8 animated" data-animate="fadeInUp"
                                            data-delay=".1">FAQs</h2>
                                        <p className="lead animated" data-animate="fadeInUp"
                                           data-delay=".2">Here are some of the most frequently asked questions. If you happen to have additional questions,
                                            please reach out via discord or twitter.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-animate="fadeInUp" data-delay="0.3"
                     className="wpb_column vc_column_container vc_col-sm-12 vc_col-md-offset-1 vc_col-md-7 animated">
                    <div className="vc_column-inner ">
                        <div className="wpb_wrapper">
                            <div className="accordion-s2" id="accordion-472">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>
                                            <a data-toggle="collapse" data-target="#collapse-751">
                                                What is the Flare network?<span className="plus-minus"><span
                                                className="ti ti-angle-up"/></span>
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-751" className="collapse show"
                                         data-parent="#accordion-472">
                                        <div className="card-body">
                                            <p>The Flare network is a new Layer-1 blockchain that aims to bridge other networks which do not support smart contracts.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h5>
                                            <a className="collapsed" data-toggle="collapse"
                                               data-target="#collapse-752">
                                                What is the native token of the Flare network?<span
                                                className="plus-minus"><span
                                                className="ti ti-angle-up"/></span>
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-752" className="collapse "
                                         data-parent="#accordion-472">
                                        <div className="card-body">
                                            <p>Spark is the native token of the Flare network: Its ticker is FLR.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h5>
                                            <a className="collapsed" data-toggle="collapse"
                                               data-target="#collapse-753">
                                                What is an FTSO?<span
                                                className="plus-minus"><span
                                                className="ti ti-angle-up"/></span>
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-753" className="collapse "
                                         data-parent="#accordion-472">
                                        <div className="card-body">
                                            <p> FTSO stands for Flare Time Series Oracle. At a high-level, it is a service designed to obtain externally sourced (off-chain)
                                                data and provide it to the Flare Network, via a suite of smart contracts, that can be utilized by other smart contracts,
                                                running on the Flare network.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h5>
                                            <a className="collapsed" data-toggle="collapse"
                                               data-target="#collapse-754">
                                                Who controls my assets?<span
                                                className="plus-minus"><span
                                                className="ti ti-angle-up"/></span>
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-754" className="collapse "
                                         data-parent="#accordion-472">
                                        <div className="card-body">
                                            <p>You are in full control. No one should ever ask you to give up possession of any of your assets
                                                to participate in the Times Series Oracle rewards.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h5>
                                            <a className="collapsed" data-toggle="collapse"
                                               data-target="#collapse-755">
                                                When can I change providers?<span
                                                className="plus-minus"><span
                                                className="ti ti-angle-up"/></span>
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-755" className="collapse "
                                         data-parent="#accordion-472">
                                        <div className="card-body">
                                            <p>You can change providers at any time.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="clearfix"/>
    </section>
);

export default FAQs;
