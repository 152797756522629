import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import delegate from "../../assets/img/delegate.svg";
import wrapWhite from "../../assets/img/wrap-white.svg";
import visibility from "../../assets/img/visibility.svg";
import digitalWallet from "../../assets/img/digital-wallet.svg";

import "../Delegate.css";
import app from "../../app";
import { observer } from "mobx-react";

const DelegateFlarePage = observer((): JSX.Element => {

  const cardHeader = {
    amount: `${app.flare.flrBalance} FLR`,
    delegated: `Delegated - ${app.flare.tsoDelegatedPercentage}% to WorldsFTSO`
  };

  const rewardCard = [
    {
      rewardButton: `Epoch #${app.flare.currentEpochNumber} Rewards`,
      delegated: "You delegated",
      amountButton: `${app.flare.currentEpochDelegatedAmount} WFLR`,
      amountDescription: `Reward Votepower at Block(${app.flare.currentEpochVoteBlock})`,
      amountStatus: `Pending Rewards ${app.flare.currentEpochPendingRewards} FLR`
    },
    {
      rewardButton: `Epoch #${app.flare.previousEpochNumber} Rewards`,
      delegated: "You delegated",
      amountButton: `${app.flare.previousEpochDelegatedAmount} WFLR`,
      amountDescription: `Reward Votepower at Block(${app.flare.previousEpochVoteBlock})`,
      amountStatus: `Pending Rewards  ${app.flare.previousEpochPendingRewards} FLR`
    }
  ];



  React.useEffect(() => {
    //Initialize Web3 Window Listeners
    //  window.addEventListener('DOMContentLoaded', setWindowListeners);
    app.flare.loadEpochDataFlare();

  }, []);

  return (
    <div id="wrapper">
      {/* Header */}
      <Header />
      {/* End Header */}
      <section
        className="vc_row wpb_row vc_row-fluid banner-salvia section-bg-salvia vc_row-o-equal-height vc_row-o-content-middle vc_row-flex banner banner-full d-flex align-items-center">
        <div className="container pt-100">
          <div className="row">
            <div className="col-lg-8 col-sm-8 pb-40">
              <div className="card shadow assets-card">
                <div className="p-4">
                  <div className="card-header-content">
                    <div className="p-10">
                      <img src={digitalWallet} alt="wallet" width="30px" style={{ paddingRight: "4px", paddingTop: "8px" }} />
                      <div
                        className="h-amount d-inline-block mr-2"> {cardHeader.amount || ""} </div>
                    </div>
                    <div className="h-delegated">
                      <div className="h-delegated-text">{cardHeader.delegated || ""}</div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-header">
                    <div className="row">
                      <div className="col-md-5">
                        Asset
                      </div>
                      <div className="col-md-3">
                        <button className="btn-hide-balance">
                          <img src={visibility} alt="kal" width="16px" style={{ verticalAlign: "middle" }} />
                        </button>
                        Balance
                      </div>
                      <div className="col-md-4">
                        Action
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="table-body-content">
                      <div className="row p-2">
                        <div className="col-md-5">
                          "Flare (FLR)"
                        </div>
                        <div className="col-sm-3">
                          {`${app.flare.flrBalance}`}
                        </div>
                        <div className="col-md-4">
                          <div className="d-inline-block">
                            <button className="custom-button"
                              type="button" onClick={(() => app.modal.openModal("wrapFlare"))}>
                              <img src={wrapWhite} alt="Wrap" style={{ marginRight: "3px", height: "20px", verticalAlign: "middle" }} />
                              {"Wrap"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-body-content">
                      <div className="row p-2">
                        <div className="col-md-5">
                          {"Wrapped Flare (WFLR)"}
                        </div>
                        <div className="col-sm-3">
                          {`${app.flare.wFLRBalance}`}
                        </div>
                        <div className="col-md-4">
                          <div className="d-inline-block">
                            <button className="custom-button"
                              type="button" onClick={(() => app.modal.openModal("delegateFlare"))}>
                              <img src={delegate} alt={"Delegate"} style={{ marginRight: "3px", height: "20px", verticalAlign: "middle" }} />
                              {"Delegate"}
                            </button>
                          </div>
                          <div className="d-inline-block">
                            {/* <button className="custom-button"
                              type="button" onClick={(() => app.modal.openModal("unwrap"))}>
                              <img src={wrapWhite} alt={"Un-Wrap"} style={{ marginRight: "3px", height: "20px", verticalAlign: "middle" }} />
                              {"Un-Wrap"}
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 pb-40">
              <div className="text-center">
                {
                  rewardCard && rewardCard.map((item, index) => {
                    return (
                      <div className="pb-2" key={index}>
                        <div className="card shadow assets-card">
                          <div className="card-body">
                            <div>
                              <span className="delegated-text">{item.rewardButton || ""}</span>
                            </div>
                            <div>
                              <span className="delegated-text">{item.delegated || ""}</span>
                              <button className="reward-button ml-1">{item.amountButton || ""}</button>
                            </div>
                            <div className="amount-description">{item.amountDescription || ""}</div>
                            <div className="delegated-text">
                              {item.amountStatus || ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <a href="#" id="back-to-top" />
    </div>
  );
});

export default DelegateFlarePage;
