
import { ethers } from "ethers";
import { action, computed, makeAutoObservable } from "mobx";
import { FTSOVotePower } from "tso-data-models/lib";

import { v4 as uuidv4 } from "uuid";
import app from "../../app";
import { flareRewardsManagerAddress, wFLRAddress } from "../../const/addresses";
import { getVotePowerPercentageFlare } from "../../utils/calculateVotePowerPercentage";
import { getDelegatedAmountAtVPBFlare } from "../../utils/getDelegatedAmountAtVPB";
import { getFlareCurrentRewardEpochNumber } from "../../utils/getFlareEpochData";
import { getFlareVotePowerBlock } from "../../utils/getFlareVotePowerBlock";
import { getPendingRewardsFlr } from "../../utils/getPendingRewards";
import { getBalanceOfwFLR } from "../../utils/getWrappedBalances";
export interface IFlareStore {
  tsoAddress: string;

  wFLRBalanceBN: string;
  wFLRBalance: number;
  flrPrice: number;
  flrBalance: number;

  tsoTotalVotePower: string;
  delegatedAmount: number;
  unDelegatedAmount: number;
  otherTSODelegatedAmount: number;
  availableToDelegateAmount: number;
  tsoDelegatedPercentage: number;

  currentEpochNumber: number;
  currentEpochVoteBlock: number;
  currentEpochPendingRewards: number;
  currentEpochDelegatedAmount: number;

  previousEpochNumber: number;
  previousEpochVoteBlock: number;
  previousEpochPendingRewards: number;
  previousEpochDelegatedAmount: number;
  availableToDelegate: number;
}

export class FlareStore implements IFlareStore {
  id = uuidv4();
  tsoAddress = "0x1a6702B5100B9AB43be23d81E919d96fE0a58D58";
  wFLRBalanceBN = "0";
  wFLRBalance = 0;
  flrPrice = 0;
  flrBalance = 0

  tsoTotalVotePower = '0';
  delegatedAmount = 0;
  unDelegatedAmount = 0;
  otherTSODelegatedAmount = 0;
  availableToDelegateAmount = 0;
  tsoDelegatedPercentage = 0;

  currentEpochNumber = 0;
  currentEpochVoteBlock = 0;
  currentEpochPendingRewards = 0;
  currentEpochDelegatedAmount = 0;

  previousEpochNumber = 0;
  previousEpochVoteBlock = 0;
  previousEpochPendingRewards = 0;
  previousEpochDelegatedAmount = 0;
  availableToDelegate = 0;

  constructor() {
    makeAutoObservable(this);
  }

  loadEpochDataFlare = async (): Promise<void> => {
    // Determine Current Epoch Data and Set Values
    //Epoch Number
    const currentRewardEpoch = await getFlareCurrentRewardEpochNumber();
    this.setCurrentEpochNumber(currentRewardEpoch);
    this.setPreviousEpochNumber(currentRewardEpoch - 1);
    console.log("Current Reward Epoch: ", currentRewardEpoch);
    //Epoch Vote Block
    const vpb = await getFlareVotePowerBlock(currentRewardEpoch);
    this.setCurrentEpochVoteBlock(vpb);
    const prevVPB = await getFlareVotePowerBlock(currentRewardEpoch - 1);
    this.setPreviousEpochVoteBlock(prevVPB);
    console.log("Current Reward Epoch Vote Block: ", vpb);
    //Epoch Delegated Amount
    //Previous Epoch Vote Block and Number
  }

  loadUserDataFlare = async (): Promise<void> => {
    //User Balances
    const flrBal = await app.web3.getCurrentBalance();
    this.setFLRBalance(flrBal);
    const wSGBBal = await getBalanceOfwFLR();
    this.setWFLRBalance(wSGBBal);
    console.log("wSGB Balance: ", wSGBBal);

    //Epoch Pending Rewards
    const currentPending = await getPendingRewardsFlr(this.currentEpochNumber);
    this.setCurrentEpochPendingRewards(currentPending);
    const previousPending = await getPendingRewardsFlr(this.previousEpochNumber);
    this.setPreviousEpochPendingRewards(previousPending);
    const delegatedPercentage = await getVotePowerPercentageFlare(app.user.address, this.tsoAddress);
    this.setTSODelegatedPercentage(delegatedPercentage);
    //Epoch Delegated Amount
    const currentDelegatedAmount = await getDelegatedAmountAtVPBFlare(this.currentEpochVoteBlock, this.tsoAddress, app.user.address);
    this.setCurrentEpochDelegatedAmount(currentDelegatedAmount);
    //Previous Epoch Delegated Amount
    const previousDelegatedAmount = await getDelegatedAmountAtVPBFlare(this.previousEpochVoteBlock, this.tsoAddress, app.user.address);
    this.setPreviousEpochDelegatedAmount(previousDelegatedAmount);

    if (window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');

      const vpReader = new FTSOVotePower(wFLRAddress, flareRewardsManagerAddress, prov);
      const tempAmount = await vpReader.getVotePowerDelegatedFromAnAddress(app.user.address, this.tsoAddress);
      this.delegatedAmount = parseFloat(app.web3.convertWEIToETH(tempAmount.toHexString()));
      this.availableToDelegate = this.wFLRBalance - this.delegatedAmount;
      const tempAmount2 = await vpReader.getUnDelegatedVotePowerOf(app.user.address);
      this.unDelegatedAmount = parseFloat(app.web3.convertWEIToETH(tempAmount2.toHexString()));
      this.otherTSODelegatedAmount = this.wFLRBalance - this.delegatedAmount - this.unDelegatedAmount;
    }
  }


  @action setTSODelegatedPercentage = (newTSODelegatedPercentage: number): void => {
    this.tsoDelegatedPercentage = newTSODelegatedPercentage;
  }

  @action setAvailableToDelegate = (newAvailableToDelegate: number): void => {
    this.availableToDelegate = newAvailableToDelegate;
  }
  @action setWFLRBalanceBN = (newBalance: string): void => {
    this.wFLRBalanceBN = newBalance;
  };
  @action setWFLRBalance = (newBalance: number): void => {
    this.wFLRBalance = newBalance;
  };
  @action setFLRPrice = (newPrice: number): void => {
    this.flrPrice = newPrice;
  };
  @action setFLRBalance = (newBalance: number): void => {
    this.flrBalance = newBalance;
  };
  @action setTSOTotalVotePower = (newTotalVotePower: string): void => {
    this.tsoTotalVotePower = newTotalVotePower;
  }
  @action setDelegatedAmount = (newDelegatedAmount: number): void => {
    this.delegatedAmount = newDelegatedAmount;
  }
  @action setUnDelegatedAmount = (newUnDelegatedAmount: number): void => {
    this.unDelegatedAmount = newUnDelegatedAmount;
  }
  @action setOtherTSODelegatedAmount = (newOtherTSODelegatedAmount: number): void => {
    this.otherTSODelegatedAmount = newOtherTSODelegatedAmount;
  }
  @action setAvailableToDelegateAmount = (newAvailableToDelegateAmount: number): void => {
    this.availableToDelegateAmount = newAvailableToDelegateAmount;
  }
  @action setCurrentEpochNumber = (newCurrentEpochNumber: number): void => {
    this.currentEpochNumber = newCurrentEpochNumber;
  }
  @action setCurrentEpochVoteBlock = (newCurrentEpochVoteBlock: number): void => {
    this.currentEpochVoteBlock = newCurrentEpochVoteBlock;
  }
  @action setCurrentEpochPendingRewards = (newCurrentEpochPendingRewards: number): void => {
    this.currentEpochPendingRewards = newCurrentEpochPendingRewards;
  }
  @action setCurrentEpochDelegatedAmount = (newCurrentEpochDelegatedAmount: number): void => {
    this.currentEpochDelegatedAmount = newCurrentEpochDelegatedAmount;
  }
  @action setPreviousEpochNumber = (newPreviousEpochNumber: number): void => {
    this.previousEpochNumber = newPreviousEpochNumber;
  }
  @action setPreviousEpochVoteBlock = (newPreviousEpochVoteBlock: number): void => {
    this.previousEpochVoteBlock = newPreviousEpochVoteBlock;
  }
  @action setPreviousEpochPendingRewards = (newPreviousEpochPendingRewards: number): void => {
    this.previousEpochPendingRewards = newPreviousEpochPendingRewards;
  }
  @action setPreviousEpochDelegatedAmount = (newPreviousEpochDelegatedAmount: number): void => {
    this.previousEpochDelegatedAmount = newPreviousEpochDelegatedAmount;
  }

  @computed getUnDelegatedPercentage(): number {
    const percentage = this.unDelegatedAmount / this.wFLRBalance;
    const valueToReturn = percentage * 100;
    if (valueToReturn > 0) {
      return parseInt(valueToReturn.toFixed(0));
    } else {
      return 0;
    }
  }



}
