import React from 'react';
import { observer } from 'mobx-react';
import app from '../../app';
import MetaMaskModal from './connect-metamask-modal/MetaMaskModal';
import DelegateModalSongbird from './delegate-modal/DelegateModalSongbird';
import WrapModal from './wrap-modal/WrapModalFlare';
import UnWrapModal from './unwrap-modal/UnWrapModal';
import WrapModalSongbird from './wrap-modal/WrapModalSongbird';
import WrapModalFlare from './wrap-modal/WrapModalFlare';
import DelegateModalFlare from './delegate-modal/DelegateModalFlare';
import { NFTpClaimModalSGB } from './nftp-claim/NFTpClaimModalSGB';

const ModalShared = observer((): JSX.Element => {
  switch (app.modal.modalToShow) {
    case 'metamaskNetwork':
      return <MetaMaskModal />;
    case 'delegateSongbird':
      return <DelegateModalSongbird />;
    case 'wrapSongbird':
      return <WrapModalSongbird />;
    case 'delegateFlare':
      return <DelegateModalFlare />;
    case 'wrapFlare':
      return <WrapModalFlare />;
    case 'nftpSGB':
      return <NFTpClaimModalSGB />;
    default:
      return <></>;
  }
});
export default ModalShared;
