import React from "react";
import { Link } from "react-router-dom";
import Logo from '../assets/img/logo-header.png';
import LogoInverted from '../assets/img/logo-inverted-header.png';

const Header = (): JSX.Element => (
  <header className="site-header is-sticky">
    {/* Navbar */}
    <div className="navbar navbar-expand-lg is-transparent" id="mainnav">
      <nav className="container">
        <h1 className="navbar-brand">
          <a href="/">
            <img className="logo" src={LogoInverted} width="170px"
              alt="Cryptico Salvia" />
            <img className="logo-2" src={Logo} width="170px"
              alt="Cryptico Salvia" />
          </a>
        </h1>
        <button className="navbar-toggler" type="button">
          <span className="navbar-toggler-icon">
            <span className="ti ti-align-justify" />
          </span>
        </button>
        <div className="navbar-collapse justify-content-end">
          <ul id="menu-onepage-menu" className="navbar-nav menu-top">
            <li id="menu-item-1056"
              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1056"><a
                href="/">Home</a></li>
            <li id="menu-item-1081"
              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1081">
              <a>Support</a>
              <ul className="sub-menu">
                <li id="menu-item-1062"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1062">
                  <a href="https://twitter.com/WorldsFTSO" rel="noreferrer" target="_blank">Twitter</a>
                </li>
                <li id="menu-item-1082"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1082">
                  <a href="https://discord.gg/zRfHWxdM" rel="noreferrer" target="_blank">Discord</a>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="navbar-nav navbar-btns">
            <li className="nav-item"><a className="nav-link btn btn-sm btn-outline menu-link" href="/delegate">Delegate</a></li>
            <Link to='/delegate' />
          </ul>
        </div>
      </nav>
    </div>
    {/* End Navbar */}
  </header>
);

export default Header;
