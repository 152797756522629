import { action, computed, makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from "uuid";
import { ethers } from 'ethers';
import app from '../../app';

export interface IWeb3Store {
  chainId: string;
  network: string;
  accounts: any;
  balance: string;
  isInitialized: boolean;

}

export class Web3Store implements IWeb3Store {
  id = uuidv4();
  chainId = '';
  network = '';
  isInitialized = false;
  accounts = [];
  balance = "0";
  //ethers: any;

  constructor() {
    makeAutoObservable(this);
  }

  _getSgbProvider(): ethers.providers.Web3Provider | ethers.providers.BaseProvider {
    if (window.ethereum !== undefined) {
      return new ethers.providers.Web3Provider(window.ethereum, 'any');
    } else {
      return new ethers.providers.JsonRpcProvider('https://songbird.towolabs.com/ext/bc/C/rpc');

    }
  }

  _getFlrProvider(): ethers.providers.Web3Provider | ethers.providers.BaseProvider {
    if (window.ethereum !== undefined) {
      return new ethers.providers.Web3Provider(window.ethereum, 'any');
    } else {
      return new ethers.providers.JsonRpcProvider('https://flare.towolabs.com/ext/bc/C/rpc');

    }
  }

  @computed convertEthToWEI = (ethToConvert: string): ethers.BigNumber => {
    return ethers.utils.parseEther(ethToConvert);
  }
  @computed isConnected = (): boolean => {
    return this.accounts.length > 0;
  }
  @computed convertWEIToETH = (weiToConvert: string): string => {
    return ethers.utils.formatUnits(weiToConvert);
  }
  @action initializeWeb3 = async (): Promise<void> => {
    window.ethereum.autoRefreshOnNetworkChange = false;
    await this.setNetworkDetails();
  }
  @action resetUser = (): void => {
    this.accounts = [];
  }
  @action setChainId = async (): Promise<void> => {
    try {
      this.chainId = await window.ethereum.request({
        method: 'eth_chainId',
      });
      this.network = getNetworkName(this.chainId);
    } catch (err) {
      console.error(err);
    }


  }
  @action setNetworkDetails = async (): Promise<void> => {
    await this.setAccounts();
    await this.setChainId();
  }
  @action getCurrentBalance = async (): Promise<number> => {
    const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const bal = await prov.getBalance(this.accounts[0]);
    const formattedBalance = ethers.utils.formatEther(bal).toString();
    const trimmedBalance = parseFloat(formattedBalance).toFixed(2);
    this.balance = trimmedBalance;
    return parseFloat(trimmedBalance);
  }

  @action setAccounts = async (): Promise<string> => {
    const tempAccounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    this.accounts = tempAccounts;
    if (app.user.address && app.user.address != tempAccounts[0]) {
      // await app.fb.processLogoutRequest();
      // window.location.reload(false);
    }
    app.user.address = tempAccounts[0];
    if (this.accounts.length > 0) {
      app.user.setIsConnected(true);
      //app.user.loadUserByAddress(app.user.address);
    }
    return tempAccounts[0];
  }
  @computed isMetaMaskConnected = (): boolean => {
    return this.accounts && this.accounts.length > 0;
  }




  @action addChain = async (): Promise<void> => {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x13',
          rpcUrls: ['https://songbird.towolabs.com/ext/bc/C/rpc'],
          chainName: 'Songbird',
          nativeCurrency: { name: 'Songbird', decimals: 18, symbol: 'SGB' },
          blockExplorerUrls: ['https://songbird-explorer.flare.network/'],
        },
      ],
    });
  }

  @action openSGBModal = (): void => {
    if (!this.isSongbirdNetwork() && !this.isFlareNetwork()) {
      app.modal.openMetaMaskNetworkModal();
    }
  }


  @computed isSongbirdNetwork = (): boolean => {
    return this.chainId === "0x13";
  }

  @computed isFlareNetwork = (): boolean => {
    return this.chainId === "0xe";
  }

  signPersonalMessage = async (signingAddress: string, messageToSign: string): Promise<string> => {
    const msg = `0x${Buffer.from(messageToSign, 'utf8').toString('hex')}`;
    const signature = await window.ethereum.request({
      method: 'personal_sign',
      params: [msg, signingAddress, 'SongbirdNFTS'],
    });
    return signature;
  }
}

export const web3Store = new Web3Store();



const getNetworkName = (chainId: string) => {
  switch (chainId) {
    case '1':
      return 'Ethereum - MainNet';
    case '3':
      return 'Ethereum - Ropsten';
    case '14':
      return 'Flare';
    case '19':
      return 'Songbird';
    case '42':
      return 'Ethereum - Kovan';
    case '4':
      return 'Ethereum - Rinkeby';
    case '97':
      return 'BSC - Testnet';
    case '56':
      return 'BSC - MainNet';
    default:
      return '';
  }

};

