import { ethers } from 'ethers';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { FTSOVotePower } from 'tso-data-models';
import { Modal, Button, FormGroup, Col, Input, Row } from 'reactstrap';
import app from '../../../app';
import { sgbRewardsManagerAddress, wFLRAddress } from '../../../const/addresses';

const DelegateModal = observer((): JSX.Element => {
  const [percentageToDelegate, setPercentageToDelegate] = useState(0);
  const [confirmUnDelegate, setConfirmUnDelegate] = useState(false);
  const [amountToDelegate, setAmountToDelegate] = useState(0);
  const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');


  const handleDelegateClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
    const vpReader = new FTSOVotePower(wFLRAddress, sgbRewardsManagerAddress, prov);
    console.log('vpReader ', vpReader);
    await vpReader.handleDelegation(app.flare.tsoAddress, percentageToDelegate);
    //  console.log('result ', result);
    app.modal.closeModal();
  };
  const handleUnDelegateClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (confirmUnDelegate) {
      try {
        const abi = ['function undelegateAll() external'];
        const delegateContract = new ethers.Contract(wFLRAddress, abi, prov.getSigner());
        await delegateContract.undelegateAll();
        // console.log('undelegateAll ', result)
        // console.log('undelegateAll result hash ', result.hash)
        app.modal.closeModal();
      } catch (error) {
        console.log('error ', error);
      }
    } else {
      setConfirmUnDelegate(true);
    }


  };

  const amountIsValid = (): boolean => {
    const isNotZero = amountToDelegate > 0;
    const isAvailable = app.flare.availableToDelegate >= amountToDelegate;
    return isNotZero && isAvailable;
  };

  const handleChangePercent = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const available = app.flare.getUnDelegatedPercentage();
    let newPercentage: number = parseInt(e.target.value);
    newPercentage = newPercentage > 100 ? 100 : newPercentage;
    if (newPercentage > available) {
      newPercentage = available;
    }
    setPercentageToDelegate(newPercentage);
    const amount = app.flare.wFLRBalance * (newPercentage / 100);
    if (amount > app.flare.availableToDelegate) {
      setAmountToDelegate(app.flare.availableToDelegate);
    } else {
      setAmountToDelegate(amount);
    }
  };
  const truncateTo5Digits = (valueToTruncate: number): string => {
    return valueToTruncate.toFixed(5);
  };

  const handlePercentageClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, percentageClicked: number) => {
    e.preventDefault();
    const available = app.flare.getUnDelegatedPercentage();
    let newPercentage: number = parseInt(percentageClicked.toString());
    newPercentage = newPercentage > 100 ? 100 : newPercentage;
    if (newPercentage > available) {
      newPercentage = available;
    }
    setPercentageToDelegate(newPercentage);

    const amount = app.flare.wFLRBalance * (newPercentage / 100);
    if (amount > app.flare.availableToDelegate) {
      setAmountToDelegate(app.flare.availableToDelegate);
    } else {
      setAmountToDelegate(amount);
    }
  };

  const warningDiv = <div>
    Warning, you are attempting to delegate more than you have available. Please lower the amount, or un-delegate from other providers to continue.
  </div>;
  const warningUnDelegateDiv = <div>
    Warning, Un-Delegating All will reset all of your delegations to 0 for all providers.
  </div>;

  const getWarningDiv = () => {
    const isValid: boolean = amountIsValid();
    const isAvailable = app.flare.availableToDelegate >= amountToDelegate;
    const isNotZero = amountToDelegate > 0;
    return isNotZero && !isAvailable && isValid ? warningDiv : <></>;
  };


  const showUnDelegateButton = (): boolean => {
    const isOther = app.flare.otherTSODelegatedAmount > 0;
    const isBest = app.flare.delegatedAmount > 0;
    return isOther || isBest;
  };

  return (
    <Modal className="Modal" isOpen={app.modal.isOpen} toggle={() => app.modal.closeModal()}>
      <div className="modal-header justify-content">
        <h4 className="title title-up">Delegate</h4>
        <div className="available-amount">Available : <strong>{truncateTo5Digits(app.flare.availableToDelegate)}</strong></div>
      </div>
      <div className="modal-body">
        <p>
          You are about to delegate your <strong>Wrapped Flare (WFLR)</strong> to <strong> Worlds TSO.</strong>
        </p>
        <div className="inputlines">
          <FormGroup>
            <label>Amount</label>
            <Row>
              <Col sm="12">
                <Input min="0" placeholder="Amount" id="amount" type="number" value={amountToDelegate} />
              </Col>
            </Row>
            <label>Percentage</label>
            <Row>
              <Col sm="12">
                <Input min="0" placeholder="0%" id="percent" type="number" value={percentageToDelegate} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangePercent(e)} />{/*  Add a max value to 100% */}
              </Col>
            </Row>

            <Row className="amount-adjustment">
              <Col sm="8">
                <Row>
                  <Col xs="3">
                    <Button color="default" className="level1" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handlePercentageClicked(e, 25)}>
                      25%
                    </Button>
                  </Col>
                  <Col xs="3">
                    <Button color="default" className="level2" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handlePercentageClicked(e, 50)}>
                      50%
                    </Button>
                  </Col>
                  <Col xs="3">
                    <Button color="default" className="level3" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handlePercentageClicked(e, 75)}>
                      75%
                    </Button>
                  </Col>
                  <Col xs="3">
                    <Button color="default" className="level4" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handlePercentageClicked(e, 100)}>
                      100%
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormGroup>


        </div>
        <div className="modaldetails warning">
          {getWarningDiv()}
          {confirmUnDelegate ? warningUnDelegateDiv : <></>}
        </div>
        <div className="modaldetails">
          <span className="left">You will delegate</span>
          <span className="right">{amountToDelegate} WFLR</span>
        </div>
        {/* <div className="modaldetails">
          <span className="left">Network fees</span>
          <span className="right">0.0012</span>
        </div> */}

      </div>
      <div className="modal-footer">
        <Button color="danger" type="button" onClick={() => app.modal.closeModal()}>
          Close
        </Button>

        <div>
          {showUnDelegateButton() ? <Button color="danger" className="redeem-unwrap" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleUnDelegateClicked(e)}>
            Un-Delegate All
          </Button> : <></>}

          {amountIsValid() ? <Button color="default" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDelegateClicked(e)}>
            Delegate
          </Button> : <></>}

        </div>
      </div>
    </Modal>
  );
});
export default DelegateModal;
