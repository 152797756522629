import { ethers } from "ethers";
import { ftsoManagerAddressFlare } from "../const/addresses";



export const getFlareCurrentRewardEpochNumber = async (): Promise<number> => {
  const abi = ['function getCurrentRewardEpoch() view returns (uint256)'];
  const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
  const ftsoManager = new ethers.Contract(ftsoManagerAddressFlare, abi, prov);
  const currentRewardEpochNumber = await ftsoManager.getCurrentRewardEpoch();
  return currentRewardEpochNumber.toNumber();
};