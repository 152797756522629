import { action, computed, makeAutoObservable } from "mobx";
import { getSongbirdCurrentRewardEpochNumber } from "../../utils/getSongbirdEpochData";
import { v4 as uuidv4 } from "uuid";
import { getSongbirdVotePowerBlock } from "../../utils/getSongbirdVotePowerBlock";
import { getDelegatedAmountAtVPBSongbird } from "../../utils/getDelegatedAmountAtVPB";
import app from "../../app";
import { getPendingRewardsSgb } from "../../utils/getPendingRewards";
import { getVotePowerPercentageSongbird } from "../../utils/calculateVotePowerPercentage";
import { FTSOVotePower } from "tso-data-models/lib";
import { ethers } from "ethers";
import { sgbRewardsManagerAddress, wSGBAddress } from "../../const/addresses";
import { getBalanceOfNFTP, getBalanceOfwSGB, getPendingNFTPRewards } from "../../utils/getWrappedBalances";

export interface ISongbirdStore {
  tsoAddress: string;

  wSGBBalanceBN: string;
  wSGBBalance: number;
  sgbPrice: number;
  sgbBalance: number;

  tsoTotalVotePower: string;
  delegatedAmount: number;
  unDelegatedAmount: number;
  otherTSODelegatedAmount: number;
  availableToDelegateAmount: number;
  tsoDelegatedPercentage: number;

  currentEpochNumber: number;
  currentEpochVoteBlock: number;
  currentEpochPendingRewards: number;
  currentEpochDelegatedAmount: number;

  previousEpochNumber: number;
  previousEpochVoteBlock: number;
  previousEpochPendingRewards: number;
  previousEpochDelegatedAmount: number;
  availableToDelegate: number;
}

export class SongbirdStore implements ISongbirdStore {
  id = uuidv4();
  tsoAddress = "0x08Eb910E9288E5C6baF6e1a74D5D1D23284360a4";
  wSGBBalanceBN = "0";
  wSGBBalance = 0;
  sgbPrice = 0;
  sgbBalance = 0;
  nftpBalance = 0;
  nftpPendingRewards = 0;

  tsoTotalVotePower = '0';
  delegatedAmount = 0;
  unDelegatedAmount = 0;
  otherTSODelegatedAmount = 0;
  availableToDelegateAmount = 0;
  tsoDelegatedPercentage = 0;

  currentEpochNumber = 0;
  currentEpochVoteBlock = 0;
  currentEpochPendingRewards = 0;
  currentEpochDelegatedAmount = 0;

  previousEpochNumber = 0;
  previousEpochVoteBlock = 0;
  previousEpochPendingRewards = 0;
  previousEpochDelegatedAmount = 0;
  availableToDelegate = 0;

  constructor() {
    makeAutoObservable(this);
  }

  @action setAvailableToDelegate = (newAvailableToDelegate: number): void => {
    this.availableToDelegate = newAvailableToDelegate;
  }

  @action setNftpBalance = (newBalance: number): void => {
    this.nftpBalance = newBalance;
  }
  @action setNFTPPendingRewards = (_pendingRewards: number): void => {
    this.nftpPendingRewards = _pendingRewards;
  }
  @action setWSGBBalanceBN = (newBalance: string): void => {
    this.wSGBBalanceBN = newBalance;
  };
  @action setWSGBBalance = (newBalance: number): void => {
    this.wSGBBalance = newBalance;
  };
  @action setSGBPrice = (newPrice: number): void => {
    this.sgbPrice = newPrice;
  };
  @action setSGBBalance = (newBalance: number): void => {
    this.sgbBalance = newBalance;
  };
  @action setTSOTotalVotePower = (newTotalVotePower: string): void => {
    this.tsoTotalVotePower = newTotalVotePower;
  }
  @action setDelegatedAmount = (newDelegatedAmount: number): void => {
    this.delegatedAmount = newDelegatedAmount;
  }
  @action setUnDelegatedAmount = (newUnDelegatedAmount: number): void => {
    this.unDelegatedAmount = newUnDelegatedAmount;
  }
  @action setOtherTSODelegatedAmount = (newOtherTSODelegatedAmount: number): void => {
    this.otherTSODelegatedAmount = newOtherTSODelegatedAmount;
  }
  @action setAvailableToDelegateAmount = (newAvailableToDelegateAmount: number): void => {
    this.availableToDelegateAmount = newAvailableToDelegateAmount;
  }
  @action setCurrentEpochNumber = (newCurrentEpochNumber: number): void => {
    this.currentEpochNumber = newCurrentEpochNumber;
  }
  @action setCurrentEpochVoteBlock = (newCurrentEpochVoteBlock: number): void => {
    this.currentEpochVoteBlock = newCurrentEpochVoteBlock;
  }
  @action setCurrentEpochPendingRewards = (newCurrentEpochPendingRewards: number): void => {
    this.currentEpochPendingRewards = newCurrentEpochPendingRewards;
  }
  @action setCurrentEpochDelegatedAmount = (newCurrentEpochDelegatedAmount: number): void => {
    this.currentEpochDelegatedAmount = newCurrentEpochDelegatedAmount;
  }
  @action setPreviousEpochNumber = (newPreviousEpochNumber: number): void => {
    this.previousEpochNumber = newPreviousEpochNumber;
  }
  @action setPreviousEpochVoteBlock = (newPreviousEpochVoteBlock: number): void => {
    this.previousEpochVoteBlock = newPreviousEpochVoteBlock;
  }
  @action setPreviousEpochPendingRewards = (newPreviousEpochPendingRewards: number): void => {
    this.previousEpochPendingRewards = newPreviousEpochPendingRewards;
  }
  @action setPreviousEpochDelegatedAmount = (newPreviousEpochDelegatedAmount: number): void => {
    this.previousEpochDelegatedAmount = newPreviousEpochDelegatedAmount;
  }
  @action setTSODelegatedPercentage = (newTSODelegatedPercentage: number): void => {
    this.tsoDelegatedPercentage = newTSODelegatedPercentage;
  }

  @action loadEpochDataSongbird = async (): Promise<void> => {
    // Determine Current Epoch Data and Set Values
    //Epoch Number
    const currentRewardEpoch = await getSongbirdCurrentRewardEpochNumber();
    this.setCurrentEpochNumber(currentRewardEpoch);
    this.setPreviousEpochNumber(currentRewardEpoch - 1);
    console.log("Current Reward Epoch: ", currentRewardEpoch);
    //Epoch Vote Block
    const vpb = await getSongbirdVotePowerBlock(currentRewardEpoch);
    this.setCurrentEpochVoteBlock(vpb);
    const prevVPB = await getSongbirdVotePowerBlock(currentRewardEpoch - 1);
    this.setPreviousEpochVoteBlock(prevVPB);
    console.log("Current Reward Epoch Vote Block: ", vpb);
    //Epoch Delegated Amount

    //Previous Epoch Vote Block and Number
  }

  @action loadUserDataSongbird = async (): Promise<void> => {
    //User Balances
    const sgbBal = await app.web3.getCurrentBalance();
    this.setSGBBalance(sgbBal);
    const wSGBBal = await getBalanceOfwSGB();
    this.setWSGBBalance(wSGBBal);
    console.log("wSGB Balance: ", wSGBBal);

    //Epoch Pending Rewards
    const currentPending = await getPendingRewardsSgb(this.currentEpochNumber);
    this.setCurrentEpochPendingRewards(currentPending);
    const previousPending = await getPendingRewardsSgb(this.previousEpochNumber);
    this.setPreviousEpochPendingRewards(previousPending);

    //Epoch Delegated Amount
    const currentDelegatedAmount = await getDelegatedAmountAtVPBSongbird(this.currentEpochVoteBlock, this.tsoAddress, app.user.address);
    this.setCurrentEpochDelegatedAmount(currentDelegatedAmount);
    //Previous Epoch Delegated Amount
    const previousDelegatedAmount = await getDelegatedAmountAtVPBSongbird(this.previousEpochVoteBlock, this.tsoAddress, app.user.address);
    this.setPreviousEpochDelegatedAmount(previousDelegatedAmount);

    const delegatedPercentage = await getVotePowerPercentageSongbird(app.user.address, this.tsoAddress);
    this.setTSODelegatedPercentage(delegatedPercentage);

    if (window.ethereum) {
      const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
      const vpReader = new FTSOVotePower(wSGBAddress, sgbRewardsManagerAddress, prov);
      const tempAmount = await vpReader.getVotePowerDelegatedFromAnAddress(app.user.address, this.tsoAddress);
      this.delegatedAmount = parseFloat(app.web3.convertWEIToETH(tempAmount.toHexString()));
      this.availableToDelegate = this.wSGBBalance - this.delegatedAmount;
      const tempAmount2 = await vpReader.getUnDelegatedVotePowerOf(app.user.address);
      this.unDelegatedAmount = parseFloat(app.web3.convertWEIToETH(tempAmount2.toHexString()));
      this.otherTSODelegatedAmount = this.wSGBBalance - this.delegatedAmount - this.unDelegatedAmount;
      //const tempDelegatedAmount = await vpReader.getTotalVotePowerOfAnAddress(this.tsoAddress);
      // const valueToTruncate = parseFloat(app.web3.convertWEIToETH(tempDelegatedAmount.toString()));
      // this.tsoTotalAmount = valueToTruncate.toFixed(0);
    }
    // get NFTP Balances
    const nftpBal = await getBalanceOfNFTP();
    this.setNftpBalance(nftpBal);
    const pendingNFTP = await getPendingNFTPRewards();
    console.log("pendingNFTP", pendingNFTP);
    this.setNFTPPendingRewards(pendingNFTP);
  }


  @computed getUnDelegatedPercentage(): number {
    const percentage = this.unDelegatedAmount / this.wSGBBalance;
    const valueToReturn = percentage * 100;
    if (valueToReturn > 0) {
      return parseInt(valueToReturn.toFixed(0));
    } else {
      return 0;
    }
  }
}
