import { ethers } from "ethers";
import { ftsoManagerAddressSongbird } from "../const/addresses";



export const getSongbirdCurrentRewardEpochNumber = async (): Promise<number> => {
  const abi = ['function getCurrentRewardEpoch() view returns (uint256)'];
  const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
  const ftsoManager = new ethers.Contract(ftsoManagerAddressSongbird, abi, prov);
  const currentRewardEpochNumber = await ftsoManager.getCurrentRewardEpoch();
  return currentRewardEpochNumber.toNumber();
};