import { BigNumber, ethers } from "ethers";
import app from "../app";
import { wFLRAddress, wSGBAddress, nftpAddressSGB } from "../const/addresses";


export const getBalanceOfwSGB = async (): Promise<number> => {
  if (window.ethereum && app.user.address) {
    if (app.user.isConnected) {
      try {
        const abi2 = ['function balanceOf(address account) external view returns (uint256)'];
        const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
        const delegateContract = new ethers.Contract(wSGBAddress, abi2, prov);
        const balance: BigNumber = await delegateContract.balanceOf(app.user.address);
        const valToReturn = app.web3.convertWEIToETH(balance.toString());
        return parseFloat(valToReturn);
      } catch (error) {
        console.log('error ', error);
        return 0;
      }
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const getBalanceOfwFLR = async (): Promise<number> => {
  if (window.ethereum && app.user.address) {
    if (app.user.isConnected) {
      try {
        const abi2 = ['function balanceOf(address account) external view returns (uint256)'];
        const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
        const delegateContract = new ethers.Contract(wFLRAddress, abi2, prov);
        const balance: BigNumber = await delegateContract.balanceOf(app.user.address);
        const valToReturn = app.web3.convertWEIToETH(balance.toString());
        return parseFloat(valToReturn);
      } catch (error) {
        console.log('error ', error);
        return 0;
      }
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};


export const getBalanceOfNFTP = async (): Promise<number> => {
  if (window.ethereum && app.user.address) {
    if (app.user.isConnected) {
      try {
        const abi2 = ['function balanceOf(address account) external view returns (uint256)'];
        const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
        const nftpContract = new ethers.Contract(nftpAddressSGB, abi2, prov);
        const balance: BigNumber = await nftpContract.balanceOf(app.user.address);
        const valToReturn = app.web3.convertWEIToETH(balance.toString());
        const formattedResult = parseFloat(valToReturn);
        const trimmedResult = formattedResult.toFixed(2);
        return parseFloat(trimmedResult);
      } catch (error) {
        console.log('error ', error);
        return 0;
      }
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};


export const getPendingNFTPRewards = async (): Promise<number> => {
  const tsoAddress = "0x08Eb910E9288E5C6baF6e1a74D5D1D23284360a4";
  if (window.ethereum && app.user.address) {
    if (app.user.isConnected) {
      try {
        const abi2 = ['function calculateClaimableRewardsSecondaryTSO(address _user, uint256 _tokenRewardPerVPBPerDenominator, address _secondaryTSOAddress) public view returns (uint256)'];
        const prov = new ethers.providers.Web3Provider(window.ethereum, 'any');
        const nftpContract = new ethers.Contract(nftpAddressSGB, abi2, prov);
        const pendingRewards: BigNumber = await nftpContract.calculateClaimableRewardsSecondaryTSO(app.user.address, 7, tsoAddress);
        const valToReturn = app.web3.convertWEIToETH(pendingRewards.toString());
        const formattedResult = parseFloat(valToReturn);
        const trimmedResult = formattedResult.toFixed(2);
        return parseFloat(trimmedResult);
      } catch (error) {
        console.log('error ', error);
        return 0;
      }
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};